/* eslint-disable react/jsx-indent */
import React from 'react';

function ProfileBadge({ onlyBadge = true }) {
  const email = localStorage.getItem('clientEmail');
  const fname = localStorage.getItem('first_Name');
  const lname = localStorage.getItem('last_Name');
  const profilePic = localStorage.getItem('profilePic');
  return (
    <button className="flex justify-center items-center gap-3 my-6 mb-10" title="View Profile">
      <img
        src={profilePic || 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60'}
        alt="profile"
        className={`${onlyBadge ? 'h-12 w-12' : 'h-8 w-8'} rounded-full object-cover`}
      />
      { onlyBadge && (
      <div className="flex-1">
          <p className="text-white font-semibold text-left w-full">{`${fname} ${lname}` || 'Aline DeShay'}</p>
        <p className="text-sm overflow-hidden overflow-ellipsis whitespace-nowrap max-w-xs w-44 text-left">{email || 'alina@yopmail.com'}</p>
      </div>
      )}
    </button>
  );
}

export default ProfileBadge;
