import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: null,
  isAuthenticated: false,
  role: null,
};

export const authSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    handleLogin: (state, action) => ({
      ...state,
      token: action.payload.token,
      role: action.payload.role,
      isAuthenticated: true,
    }),
    handleLogout: (state) => ({
      ...state,
      token: null,
      isAuthenticated: false,
    }),
  },
});

export const { handleLogin, handleLogout } = authSlice.actions;

export default authSlice.reducer;
