/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';

function OtpInput({ digits = 6, onChange = () => {} }) {
  const [otp, setOtp] = useState([]);

  useEffect(() => {
    const otpInputs = document.querySelectorAll('.otp-input');
    otpInputs[0].focus();
  }, []);

  const handleChange = (e) => {
    // get input classe

    const temp = [...otp];
    temp[e.target.tabIndex] = e.target.value;
    onChange(temp.join(''));
    setOtp(temp);
  };

  const handleFocus = (e) => {
    const otpInputs = document.querySelectorAll('.otp-input');

    if (e.code === 'Backspace' && e.target.tabIndex > 0) {
      otpInputs[e.target.tabIndex - 1].focus();
    }

    if (e.code !== 'Backspace' && e.target.tabIndex < digits - 1) {
      otpInputs[e.target.tabIndex + 1].focus();
    }
  };

  return (
    <div className="flex items-center justify-between gap-2">
      {
      Array(digits).fill('1').map((el, i) => (
        <input
          type="text"
          tabIndex={i}
          min={0}
          max={9}
          onChange={handleChange}
          onKeyUp={handleFocus}
          maxLength={1}
          placeholder="__"
          className="otp-input rounded-lg p-3 w-12 text-center"
          pattern="[0-9]"
          key={`otp-input-${i}`}
        />
      ))
    }

    </div>
  );
}

export default OtpInput;
