import { configureStore } from '@reduxjs/toolkit';
import { silverLiningClientApi } from '../services/api';
import rootReducer from './rootReducer';
import authReducer from './authentication';
// import handle401And403Middleware from '../services/middleware/apiMiddleware';

const store = configureStore({
  reducer: {
    [silverLiningClientApi.reducerPath]: silverLiningClientApi.reducer,
    ...rootReducer,
    authentication: authReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(silverLiningClientApi.middleware),
});

export default store;
