import { notification } from '../../components';

const handleUnauthorized = async (errorOrOptions) => {
  const error = errorOrOptions.error || errorOrOptions;
  if (error.status === 401 || error.status === 403) {
    notification({
      title: 'Error',
      description: error?.data?.message,
      type: 'error',
    });

    localStorage.clear();
    setTimeout(() => {
      window.location.href = '/login';
    }, 1000);
  }
};

export default handleUnauthorized;
